import React from "react"
import { graphql } from "gatsby"
import SEO from '../components/SEO'
import Layout from "../components/Layout"
import {Header, Divider} from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown'
import ProductList from "../components/ProductList"

export const query = graphql`
  query CategoryQuery($id: String) {
    category:strapiCategory(strapiId: { eq: $id }) {
      strapiId
      Name
      Description
      MetaDescription
      Image {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
          compressed:sizes(sizes: "200") {
            src
          }
        }
      }
      Products {
        id
        Name
        Image {
          childImageSharp {
            sizes(maxWidth: 250) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        Price
      }
    }
  }
`

const Category = ({ data, location }) => {
  const category = data.category;
  const metadata = {
    description: category.MetaDescription,
    title: category.Name,
    image: category.Image.childImageSharp.compressed.src
  }
  location.category = category
  location.category.id = category.strapiId
  return (
    <Layout location = {location} >
      <SEO title={category.Name} metadata={metadata}/>
      <Header
        as="h1"
        textAlign="center"
        style={{
          marginBottom: '1em',
        }}
      >
        <Header.Content
          style={{
            width: '60%',
            margin: '0 auto',
          }}
        >
          {category.Name}
        </Header.Content>
      </Header>
      <ReactMarkdown source={category.Description} 
        style={{
          marginBottom: '2em',
        }}/>
      <Divider style={{
          marginBottom: '2em',
        }}/>
      <ProductList products={category.Products} />
    </Layout>
  )
}

export default Category
