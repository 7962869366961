import React from 'react'
import {Card} from 'semantic-ui-react'
import Img from 'gatsby-image'
import {Link} from 'gatsby'
import CurrencyFormat from 'react-currency-format';

const mapProducts = products =>
  products.map(({id, Name, Image, Price}) => {
    const meta = (
      <Card.Meta style={{color: 'dimgray'}}>
        <CurrencyFormat value={Price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
      </Card.Meta>
    )
    return {
      as: Link,
      to: `/product/${id}/`,
      childKey: id,
      image: <Img sizes={Image.childImageSharp.sizes} alt={Name} />,
      header: Name,
      meta: meta,
    }
  }
)


const ProductList = ({ products }) => {
  const items = mapProducts(products)
  return (
    <Card.Group items={items} itemsPerRow={2} stackable />
  )
}

export default ProductList
